import React from 'react'
import '../../App.css'
import Cards from '../Cards';

function Event() {
  return (
    <div className='team'><h1 className='heading'>Event</h1>
    <Cards/></div>
  )
}

export default Event;